// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-collaborations-js": () => import("./../../../src/pages/collaborations.js" /* webpackChunkName: "component---src-pages-collaborations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../../../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-collaboration-js": () => import("./../../../src/templates/collaboration.js" /* webpackChunkName: "component---src-templates-collaboration-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */),
  "component---src-templates-technology-js": () => import("./../../../src/templates/technology.js" /* webpackChunkName: "component---src-templates-technology-js" */)
}

